import React from 'react'
import { Box } from '@chakra-ui/react'

import SEO from '~components/shared/SEO'
import ProjectsHeader from '~components/projects/projects-header'
import FeaturedProjects from '~components/projects/featured-projects'

export default function ProjectsPage() {
    return (
        <>
            <SEO
                title="Projects"
                description="View the clients we've worked with and read about how he helped them break through the starting phase into being leaders in their industries."
            />
            <Box bg="gray.50">
                <ProjectsHeader />
                <FeaturedProjects />
            </Box>
        </>
    )
}
