import React from 'react'
import { Box, Center, Flex, Heading, Text, Image } from '@chakra-ui/react'

import { FadeIn, FadeUpBox } from '~components/shared/custom-animation'

import ProjectsBg from '~images/backgrounds/projects-bg.svg'
import projects from '~images/services/cool_devices.svg'

const ProjectsHeader = () => {
    return (
        <Box
            h="100vh"
            backgroundImage={`url(${ProjectsBg})`}
            backgroundPosition="center"
            bgRepeat="no-repeat"
            backgroundSize="cover"
        >
            <Center w="100%" h="100%" align="center" justify="space-between">
                <Flex
                    w="100%"
                    h={{ base: '40%', md: '100%' }}
                    direction={{ base: 'column', md: 'row' }}
                    align="center"
                    justify="space-between"
                    px="10%"
                >
                    <Box
                        w={{ base: '100%', sm: '60%', md: '50%', lg: '45%' }}
                        mt={{ base: 12, md: 0 }}
                    >
                        <FadeIn>
                            <Image
                                src={projects}
                                w="100%"
                                h="100%"
                                objectFit="cover"
                            />
                        </FadeIn>
                    </Box>
                    <Box w={{ base: '100%', md: '45%', lg: '50%' }}>
                        <FadeUpBox>
                            <Heading
                                textAlign={{ base: 'center', md: 'left' }}
                                fontSize={{
                                    base: 'lg',
                                    xl: '2xl',
                                }}
                                lineHeight={{ base: '40px', xl: '60px' }}
                                mb={2}
                            >
                                Let our projects do the talking
                            </Heading>
                        </FadeUpBox>
                        <FadeUpBox>
                            <Text
                                textAlign={{ base: 'center', md: 'left' }}
                                fontSize={{ base: 'sm', xl: 'md' }}
                                color="gray.500"
                            >
                                Look through some of our previous clients and
                                see how we helped them take their businesses to
                                the next level.
                            </Text>
                        </FadeUpBox>
                    </Box>
                </Flex>
            </Center>
        </Box>
    )
}

export default ProjectsHeader
