import React from 'react'
import { navigate, Link } from 'gatsby'
import {
    Button,
    Box,
    Flex,
    Heading,
    Text,
    Image,
    Grid,
    GridItem,
} from '@chakra-ui/react'

import ResponsiveBlock from '~components/shared/responsive-block'
import MentrImg from '~images/backgrounds/mentr-mockup.png'
import DhlImg from '~images/backgrounds/dhlabs-mockups.png'
import CLImg from '~images/backgrounds/cl-mockups.png'
import Polygon from '~images/backgrounds/polygon.svg'
import Crack from '~images/backgrounds/help.svg'
import MBImg from '~images/backgrounds/mb-mockups.png'
import {
    FadeIn,
    FadeUpBox,
    SlideInBox,
} from '~components/shared/custom-animation'

const FeaturedProjects = () => (
    <ResponsiveBlock>
        <Grid
            columnGap="64px"
            rowGap={{ base: '32px', md: '96px' }}
            templateColumns={{
                base: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
            }}
            pb={24}
        >
            <GridItem colSpan={1}>
                <Flex w="100%" h="100%" justify="center" align="center">
                    <ProjectInfo
                        title=" Mallard Bay Outdoors"
                        desc=" Disrupting an Industry and Bringing
                        Ideas to Life"
                        buttonText="Here's How We Did It"
                        buttonColor="#F47E3E"
                        link={'/casestudy/mallard-bay'}
                    />
                </Flex>
            </GridItem>
            <GridItem colSpan={1}>
                <Box>
                    <SlideInBox>
                        <Flex
                            width="100%"
                            h="350px"
                            bg="#F47E3E"
                            boxShadow="md"
                            align="center"
                            justify="center"
                            pb="32px"
                        >
                            <FadeUpBox delay={0.4}>
                                <Image src={MBImg} width="100%" />
                            </FadeUpBox>
                        </Flex>
                    </SlideInBox>
                </Box>
            </GridItem>
            <GridItem colSpan={1}>
                <Box>
                    <SlideInBox>
                        <Flex
                            width="100%"
                            h="350px"
                            bg="#ccf1f1"
                            boxShadow="md"
                            align="center"
                            justify="center"
                            pb="32px"
                        >
                            <FadeUpBox delay={0.4}>
                                <Image src={MentrImg} width="100%" />
                            </FadeUpBox>
                        </Flex>
                    </SlideInBox>
                </Box>
            </GridItem>
            <GridItem colSpan={1}>
                <Flex w="100%" h="100%" justify="center" align="center">
                    <ProjectInfo
                        title="LS-imPACt"
                        desc="Social Network for Academia"
                        buttonText="Here's How We Did It"
                        buttonColor="#86C2C8"
                        link={'/casestudy/ls-imPACt'}
                    />
                </Flex>
            </GridItem>
            <GridItem colSpan={1}>
                <Flex w="100%" h="100%" justify="center" align="center">
                    <ProjectInfo
                        title="Centerline"
                        desc="Construction Management, Reimagined"
                        buttonText="Here's How We Did It"
                        buttonColor="#3f6cbf"
                        link={'/casestudy/centerline'}
                    />
                </Flex>
            </GridItem>
            <GridItem colSpan={1}>
                <Box>
                    <SlideInBox>
                        <Flex
                            width="100%"
                            h="350px"
                            bg="#3f6cbf"
                            boxShadow="md"
                            align="center"
                            justify="center"
                            pb="32px"
                        >
                            <FadeUpBox delay={0.4}>
                                <Image src={CLImg} width="100%" />
                            </FadeUpBox>
                        </Flex>
                    </SlideInBox>
                </Box>
            </GridItem>

            <GridItem colSpan={{ base: 1, md: 2 }}>
                <FadeIn>
                    <Box h="250px" bg="#ccc" boxShadow="md">
                        <Flex
                            backgroundImage={`url(${Polygon})`}
                            backgroundRepeat={'no-repeat'}
                            backgroundPosition={'center'}
                            backgroundSize={'cover'}
                            w="100%"
                            h="100%"
                            direction="column"
                            justify="center"
                            align="center"
                        >
                            <Heading pb="32px" color="white">
                                Big or Small,
                            </Heading>
                            <Heading fontSize="sm" color="white">
                                we obsess over every detail
                            </Heading>
                        </Flex>
                    </Box>
                </FadeIn>
            </GridItem>
            <GridItem colSpan={1}>
                <Box>
                    <SlideInBox>
                        <Flex
                            width="100%"
                            h="350px"
                            bg="#bbb"
                            boxShadow="md"
                            align="center"
                            justify="center"
                            pb="32px"
                        >
                            <FadeUpBox delay={0.4}>
                                <Flex
                                    w="100%"
                                    h="100%"
                                    justify="center"
                                    align="center"
                                >
                                    <Image src={DhlImg} width="90%" />
                                </Flex>
                            </FadeUpBox>
                        </Flex>
                    </SlideInBox>
                    <ProjectInfo
                        title={'Dark Horse Labs'}
                        desc={'Speed Should Never Sacrifice Quality'}
                        buttonText={'See More'}
                        buttonColor={'#777'}
                        pt="32px"
                        link={'https://dhlabs.io'}
                    />
                </Box>
            </GridItem>
            <GridItem colSpan={1}>
                <Box>
                    <SlideInBox>
                        <Flex
                            h="350px"
                            bg="blue.100"
                            boxShadow="md"
                            justify="center"
                            align="center"
                        >
                            <FadeUpBox delay={0.4}>
                                <Image src={Crack} height="100%" width="100%" />
                            </FadeUpBox>
                        </Flex>
                    </SlideInBox>
                    <ProjectInfo
                        title={'Your Project Here'}
                        desc={'See what we can do for you'}
                        buttonText={"Let's Get Started"}
                        buttonColor={'blue.400'}
                        pb="32px"
                        pt="32px"
                        link={'/contact'}
                    />
                </Box>
            </GridItem>
        </Grid>
    </ResponsiveBlock>
)

const ProjectInfo = ({
    title,
    desc,
    buttonText,
    buttonColor,
    link,
    ...props
}) => (
    <FadeUpBox>
        <Flex
            align="center"
            justify="space-around"
            flexWrap="wrap"
            h="100%"
            width="100%"
            {...props}
        >
            <Box textAlign="center" pb="10px" w="100%">
                <Heading fontSize="lg">{title}</Heading>
                <Text color="gray.600">{desc}</Text>
            </Box>
            <Link to={link}>
                <Button
                    bg={buttonColor || 'brand.default'}
                    color="white"
                    fontSize="sm"
                    position={null}
                >
                    {buttonText}
                </Button>
            </Link>
        </Flex>
    </FadeUpBox>
)

export default FeaturedProjects
